import { type JSX } from 'preact';
import { Signal } from '@preact/signals';
import { useCallback, useEffect, useRef } from 'preact/hooks';
import { filters } from 'signals/filters';
import { OfferCardList } from 'components/offer/list/OfferList';
import { SignalsProvider } from 'context/SignalsContext';
import { updateOfferStatus, type GetOffersResponse, setReserved, setIsActive } from 'service/offer/resource';
import { type OfferData } from 'service/offer/model';
import { isRowOrCardView, multiselectOffers } from 'components/bazar/signals';
import {
	isQueryOffersLoading,
	isQueryOffersAppend,
	handleCategoryQueries,
	refreshCategoryCounts,
} from 'signals/queryOffers';
import { toastMessages } from 'components/toastMessage/signals.ts';
import { localize, populate } from 'i18n/localize.ts';
import { cn } from 'utils/cn';
import { OFFERS_PER_PAGE } from 'utils/constants.ts';
import {
	sendDeleteOfferAnalytics,
	sendPublishOfferAnalytics,
	sendReserveOfferAnalytics,
	sendWithdrawOfferAnalytics,
} from 'lib/dot/seller/utils.ts';
import { user } from 'signals/login.ts';
import { offers } from 'signals/offers';
import { useLocalStorageValue } from 'utils/LocalStorage';

export type Props = {
	isAdmin: boolean;
	ssrOffers: GetOffersResponse;
	voucherCount?: number;
};

const $t = localize();

export function BazarListIsland({ isAdmin, ssrOffers, voucherCount }: Props) {
	const holdingShift = useRef(false);
	const lastMultiSelectedAdvertId = useRef<number>();
	const rowOrCardSignals = useLocalStorageValue(isRowOrCardView);
	const isRowView = !!(isAdmin && rowOrCardSignals.isMounted.value && rowOrCardSignals.signal.value);

	if (offers.peek() === undefined) offers.value = ssrOffers ?? [];

	const onMultiselectOfferClick = useCallback(
		(event: JSX.TargetedMouseEvent<HTMLInputElement>, offer?: OfferData) => {
			if (!offer) {
				return;
			}

			const target = event.target as null | HTMLInputElement;
			if (target?.checked) {
				multiselectOffers.add(offer);
			} else {
				multiselectOffers.delete(offer.id);
			}

			// Multiple offers from one click to another when holding shift
			if (
				holdingShift.current &&
				lastMultiSelectedAdvertId.current &&
				offers.value &&
				offers.value.results.length
			) {
				const lastIndex = offers.value.results.findIndex(
					(item) => item.id === lastMultiSelectedAdvertId.current,
				);
				const currentIndex = offers.value.results.findIndex((item) => item.id === offer.id);
				const start = Math.min(lastIndex, currentIndex);
				const end = Math.max(lastIndex, currentIndex);

				for (let i = start; i <= end; i++) {
					if (!offers.value?.results && !offers.value?.results[i]) {
						continue;
					}

					if (target?.checked) {
						multiselectOffers.add(offers.value.results[i]! as OfferData); // Unpacked offers can't make it to BazarList
					} else {
						multiselectOffers.delete(offers.value.results[i]!.id as OfferData['id']); // Unpacked offers can't make it to BazarList
					}
				}
			}

			lastMultiSelectedAdvertId.current = offer.id;
		},
		[multiselectOffers],
	);

	useEffect(() => {
		const onKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Shift') {
				holdingShift.current = true;
			}
		};

		const onKeyUp = (event: KeyboardEvent) => {
			if (event.key === 'Shift') {
				holdingShift.current = false;
			}
		};

		globalThis.document.addEventListener('keydown', onKeyDown);
		globalThis.document.addEventListener('keyup', onKeyUp);

		return () => {
			globalThis.document.removeEventListener('keydown', onKeyDown);
			globalThis.document.removeEventListener('keyup', onKeyUp);
		};
	}, []);

	return (
		<SignalsProvider
			value={{
				offers: new Signal({
					response: offers.value,
					isLoading: isQueryOffersLoading.value,
					isAppend: isQueryOffersAppend.value,
				}),
			}}
		>
			<OfferCardList
				noAds={isAdmin}
				isRowView={isRowView}
				gridSizing={cn(
					isRowView
						? 'grid-cols-1'
						: 'grid-cols-2 smp:grid-cols-3 md:@md:grid-cols-3 md:@lg:grid-cols-4 md:@2xl:grid-cols-5 4xl:grid-cols-6',
				)}
				positionOffset={((filters.peek().page.value ?? 1) - 1) * OFFERS_PER_PAGE}
				offerCardProps={{
					voucherCount,
					showVideoStatus: isAdmin,
					showEditActions: isAdmin,
					showMultiselect: isAdmin,
					showInactive: isAdmin,
					showUnpackedBadge: false,
					multiselectInputProps: {
						onClick: onMultiselectOfferClick,
					},
					onOfferDelete: (_event, offer, reason) => {
						if (!offer.id) {
							toastMessages.addMessage(
								populate($t.offer.deletionError, { ADVERT_NAME: offer.name! }),
								'error',
							);
							return;
						}

						updateOfferStatus({ ids: [offer.id], status_deleted: true, sold: reason })
							.then((res) => {
								if (res.status === 200) {
									handleCategoryQueries(filters.peek())
										.then((response) => {
											refreshCategoryCounts(response, filters.peek());
										})
										.catch(() => {});

									const pagination = {
										...offers.value.pagination,
										total: offers.value.pagination.total - 1,
									};
									const results = offers.value.results.filter((item) => item.id !== offer.id);

									offers.value = { pagination, results };

									sendDeleteOfferAnalytics(
										offer as OfferData,
										user.peek().uid!,
										reason ?? 'not_specified',
									);
								}
							})
							.catch(() => {
								toastMessages.addMessage(
									populate($t.offer.deletionError, { ADVERT_NAME: offer.name! }),
									'error',
								);
							});
					},
					onOfferToggleVisibility: (_event, offer, reason) => {
						const isPublishing = offer.status !== 'active';
						const translationKey = isPublishing ? $t.offer.activateError : $t.offer.inactivateError;
						const errorMessage = populate(translationKey, { ADVERT_NAME: offer.name! });

						setIsActive([offer as OfferData], isPublishing, reason)
							.then((res) => {
								if (res.status === 200) {
									offer.status = isPublishing ? 'active' : 'inactive';
									const currentStatus = filters.peek().status;

									offers.value = { ...offers.value };

									if (
										(currentStatus === 'active' && !isPublishing) ||
										(currentStatus === 'inactive,disabled' && isPublishing)
									) {
										const results = offers.value.results.filter((item) => item.id !== offer.id);
										const pagination = {
											...offers.value.pagination,
											total: offers.value.pagination.total - 1,
										};

										offers.value = { pagination, results };
										handleCategoryQueries(filters.peek())
											.then((response) => {
												refreshCategoryCounts(response, filters.peek());
											})
											.catch(() => {});

										toastMessages.addMessage(
											currentStatus === 'active'
												? $t.offer.movingToInactive
												: $t.offer.movingToActive,
											'success',
										);
									}

									if (isPublishing) {
										sendPublishOfferAnalytics(offer as OfferData, 'inactive', user.peek().uid!);
									} else {
										sendWithdrawOfferAnalytics(
											offer as OfferData,
											user.peek().uid!,
											reason ?? 'not_specified',
										);
									}
								}
							})
							.catch(() => {
								toastMessages.addMessage(errorMessage, 'error');
							});
					},
					onOfferReserve: (_event, offer) => {
						const isReserving = offer.isReserved !== true;
						const translationKey = isReserving ? $t.offer.reservationError : $t.offer.unreservationError;
						const errorMessage = populate(translationKey, { ADVERT_NAME: offer.name! });
						const originalOfferStatus = offer.status;
						if (!offer.id) {
							toastMessages.addMessage(errorMessage, 'error');
							return;
						}

						setReserved(offer.id, !offer.isReserved)
							.then((res) => {
								if (res.status === 200) {
									offer.isReserved = isReserving;
									offer.status = 'active';
									offers.value = { ...offers.value };
									sendReserveOfferAnalytics(offer as OfferData, user.peek().uid!, isReserving);

									// (Un)reserving an offer forces it to become active. Refresh category counts if its status changed.
									if (originalOfferStatus !== 'active') {
										handleCategoryQueries(filters.peek())
											.then((response) => {
												refreshCategoryCounts(response, filters.peek());
											})
											.catch(() => {});
									}
								}
							})
							.catch(() => {
								toastMessages.addMessage(errorMessage, 'error');
							});
					},
				}}
			/>
		</SignalsProvider>
	);
}
