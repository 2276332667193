import { useCallback } from 'preact/hooks';
import { useSignal } from '@preact/signals';
import { Icon } from 'components/atoms/Icon';
import { Pill } from 'components/atoms/Pill';
import { VerificationModal } from 'components/modal/verification/VerificationModal.tsx';
import { localize } from 'i18n/localize.ts';
import { sendVerifyProfileOpenModalAnalytics } from 'lib/dot/seller/utils.ts';
import { userBazar } from 'components/bazar/signals.ts';

const $t = localize();

type Props = {
	className: string;
};

export function VerificationButton({ className }: Props) {
	const isModalOpen = useSignal(false);

	const onClose = useCallback(() => {
		isModalOpen.value = false;
	}, [isModalOpen.value]);

	const onOpen = useCallback(() => {
		sendVerifyProfileOpenModalAnalytics(userBazar.peek()!.id);
		isModalOpen.value = true;
	}, [isModalOpen.value]);

	return (
		<div>
			<Pill pillType="secondary" onClick={onOpen} className={className}>
				<Icon type="check_circle" fill size={24} />
				{$t.verification.openButton}
			</Pill>
			{isModalOpen.value && <VerificationModal onClose={onClose} />}
		</div>
	);
}
