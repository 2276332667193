import { localize, pluralize } from 'i18n/localize';
import { number } from 'utils/format';
import { offers } from 'signals/offers';
import { cn } from 'utils/cn';

const { offer } = localize();

type Props = {
	className?: string;
};

export function OffersCount({ className }: Props) {
	return (
		<span class={cn('text-dark-blue-50 text-sm', className)}>
			{pluralize(offers.value.pagination.total, offer.count, {
				count: number(offers.value.pagination.total),
			})}
		</span>
	);
}
