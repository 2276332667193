import { useCallback } from 'preact/hooks';
import { Icon } from 'components/atoms/Icon';
import { cn } from 'utils/cn';
import { isRowOrCardView } from 'components/bazar/signals';
import { useLocalStorageValue } from 'utils/LocalStorage';

type Props = {
	className?: string;
};

export function RowsOrCards({ className }: Props) {
	const isRowOrCardViewLocal = useLocalStorageValue(isRowOrCardView);
	const onChangeToCard = useCallback(() => {
		isRowOrCardViewLocal.signal.value = false;
	}, []);

	const onChangeToRow = useCallback(() => {
		isRowOrCardViewLocal.signal.value = true;
	}, []);

	return (
		<div class={cn('leading-0 text-neutral-20', className)}>
			<button class="leading-0 mr-2" onClick={onChangeToCard}>
				<Icon
					className={cn(
						(!isRowOrCardViewLocal.isMounted.value ||
							(isRowOrCardViewLocal.isMounted.value && !isRowOrCardViewLocal.signal.value)) &&
							'text-dark-blue',
					)}
					type="window"
					size={20}
					fill
				/>
			</button>
			<button class="leading-0" onClick={onChangeToRow}>
				<Icon
					className={cn(
						isRowOrCardViewLocal.isMounted.value && isRowOrCardViewLocal.signal.value && 'text-dark-blue',
					)}
					type="lists"
					size={20}
				/>
			</button>
		</div>
	);
}
