import { useCallback, useEffect, useRef } from 'preact/hooks';
import { multiselectOffers } from 'components/bazar/signals';
import { Pill } from 'components/atoms/Pill';
import { Icon } from 'components/atoms/Icon';
import { localize, pluralize } from 'i18n/localize';
import { queryOffers } from 'signals/queryOffers';
import { filters } from 'signals/filters';
import { updateAdvertsStatus, type UpdateStatusSummary, type UpdateOffersStatusParams } from 'service/offer/resource';
import { toastMessages } from 'components/toastMessage/signals';
import { Dropdown } from 'components/dropdown/Dropdown';
import { IconOption, type IconOptionType } from 'components/dropdown/options/IconOption';
import { STATE } from 'utils/state.ts';
import { cn } from 'utils/cn';

const $t = localize();

type Props = {
	className?: string;
};

type OptionId = 'activate' | 'delete' | 'reserve' | 'unreserve' | 'inactivate';

export function MultiselectBar({ className }: Props) {
	const statusFilter = filters.value.status;
	const dropdownContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		multiselectOffers.disable();
	}, [statusFilter]);

	const selectedOffers = multiselectOffers.value.selectedOffers;
	if (!multiselectOffers.value.enabled || multiselectOffers.value.selectedOffers.size === 0) {
		return <div></div>;
	}

	const deleteOption: IconOptionType<OptionId> = { id: 'delete', icon: 'delete' };
	const options: IconOptionType<OptionId>[] =
		statusFilter === STATE.ACTIVE
			? [{ id: 'inactivate', icon: 'visibility_off' }, deleteOption]
			: statusFilter === STATE.INACTIVE
				? [{ id: 'activate', icon: 'visibility' }, deleteOption]
				: [deleteOption];

	const onOptionSelected: Dropdown.OptionSelectedHandler<IconOptionType<OptionId>> = useCallback((_event, option) => {
		switch (option.id) {
			case 'activate':
				onActivate();
				break;
			case 'inactivate':
				onInactivate();
				break;
			case 'delete':
				onDelete();
				break;
		}
	}, []);

	const handleUpdateStatusErrors = (summary: UpdateStatusSummary[], errorTranslationKey: string) => {
		for (const itemSummary of summary) {
			if (itemSummary.errors?.length) {
				const offer = selectedOffers.get(itemSummary.advert_id);
				if (offer) {
					toastMessages.addMessage(pluralize(1, [errorTranslationKey], { ADVERT_NAME: offer.name }), 'error');
				}
			}
		}
	};

	const updateMultiSelectedStatus = (
		status: Omit<UpdateOffersStatusParams, 'ids'>,
		translation: {
			singleItemErrorMessage: string;
			batchErrorMessage: string;
			successMessage: string;
		},
	) => {
		const asyncAction = async () => {
			const ids = Array.from(multiselectOffers.value.selectedOffers.keys());
			try {
				const result = await updateAdvertsStatus({ ids, ...status });
				handleUpdateStatusErrors(result.summary || [], translation.singleItemErrorMessage);

				if (result.summary?.every((item) => item.operation_ok)) {
					toastMessages.addMessage(translation.successMessage, 'success');
				}
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
			} catch (error) {
				toastMessages.addMessage(translation.batchErrorMessage, 'error');
			}

			try {
				queryOffers();
			} catch (error) {
				console.error('Reload offers error', error);
			}
			multiselectOffers.disable();
		};

		asyncAction();
	};

	const onInactivate = useCallback(() => {
		updateMultiSelectedStatus(
			{ status_active: false },
			{
				singleItemErrorMessage: $t.offer.inactivateError,
				batchErrorMessage: $t.multiselect.inactivateOffersError,
				successMessage: $t.multiselect.inactivateSuccess,
			},
		);
	}, [updateMultiSelectedStatus]);

	const onActivate = useCallback(() => {
		updateMultiSelectedStatus(
			{ status_active: true },
			{
				singleItemErrorMessage: $t.offer.activateError,
				batchErrorMessage: $t.multiselect.activateOffersError,
				successMessage: $t.multiselect.activateSuccess,
			},
		);
	}, [updateMultiSelectedStatus]);

	const onDelete = useCallback(() => {
		updateMultiSelectedStatus(
			{ status_deleted: true },
			{
				singleItemErrorMessage: $t.offer.deletionError,
				batchErrorMessage: $t.multiselect.deletionOffersError,
				successMessage: $t.multiselect.deletionSuccess,
			},
		);
	}, [updateMultiSelectedStatus]);

	const renderOption = ({
		props,
		option,
		isFocused,
		aria,
		id,
	}: Dropdown.RenderOptionProps<IconOptionType<OptionId>>) => (
		<IconOption
			label={$t.multiselect.dropdown[option.id]}
			icon={option.icon}
			props={props}
			isFocused={isFocused}
			aria={aria}
			id={id}
			dataTest={option.id}
		/>
	);
	return (
		<>
			<div
				className={cn(
					'fixed md:sticky bottom-3 z-10 left-0 w-[calc(100%-16px)] mx-2 md:w-auto shadow-sm bg-white py-3 pl-6 pr-6 md:pr-4 rounded-full animate-transformFromBottom flex items-center',
					className,
				)}
				data-test="multiselect-bar"
			>
				<div className="text-sm font-medium">
					<span className="hidden md:inline">
						{pluralize(multiselectOffers.value.selectedOffers.size, $t.multiselect.selectedCount, true)}
					</span>
					<span className="inline md:hidden">
						{pluralize(
							multiselectOffers.value.selectedOffers.size,
							$t.multiselect.selectedCountCompact,
							true,
						)}
					</span>
				</div>
				<div class="flex-grow text-right flex items-center gap-3 justify-end">
					{statusFilter === STATE.ACTIVE && (
						<Pill
							className="hidden md:flex justify-center items-center gap-2"
							pillType="secondary"
							onClick={onInactivate}
						>
							<Icon type="visibility_off" size={24} fill />
							{$t.multiselect.inactivate}
						</Pill>
					)}
					{statusFilter === STATE.INACTIVE && (
						<Pill
							className="hidden md:flex justify-center items-center gap-2"
							pillType="secondary"
							onClick={onActivate}
						>
							<Icon type="visibility" size={24} fill />
							{$t.multiselect.activate}
						</Pill>
					)}
					<Pill
						className="hidden md:flex justify-center items-center gap-2"
						pillType="secondary"
						onClick={onDelete}
					>
						<Icon type="delete" size={24} fill />
						{$t.multiselect.delete}
					</Pill>

					<Dropdown
						renderOpener={({ toggleOpen, openerRef, onKeyDown, aria }) => (
							<button
								className="flex justify-center items-center gap-2 text-sm font-medium md:hidden"
								onClick={toggleOpen}
								ref={openerRef}
								onKeyDown={onKeyDown}
								{...aria}
							>
								<Icon className="text-dark-blue-25" type="more_horiz" size={16} fill />
								{$t.multiselect.options}
							</button>
						)}
						mobileTitle={$t.multiselect.options}
						renderOption={renderOption}
						options={options}
						onOptionSelected={onOptionSelected}
						containerPortalRefElement={dropdownContainerRef}
						containerAriaRole="menu"
						optionAriaRole="menuitem"
						idPrefix="multiselect-bar"
					/>
				</div>
			</div>
			<div ref={dropdownContainerRef}></div>
		</>
	);
}
