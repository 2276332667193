import { multiselectOffers } from 'components/bazar/signals';
import { offers } from 'signals/offers';
import { localize } from 'i18n/localize';
import type { OfferData } from 'service/offer/model.ts';

const $t = localize();

type Props = {
	isAdmin: boolean;
};

export function SelectOffers({ isAdmin }: Props) {
	const showMultiSelect = isAdmin && offers.value.results.length > 0;

	return (
		<>
			{showMultiSelect && <div className="whitespace-pre text-dark-blue-50 text-sm"> – </div>}
			<div className="text-dark-blue-50 text-sm shrink-0 flex items-center">
				{showMultiSelect &&
					(multiselectOffers.value.enabled ? (
						<button
							className="link"
							onClick={() => {
								multiselectOffers.selectAll(offers.value.results as OfferData[]); // Unpacked offers can't be the subject of multiselect
							}}
						>
							{$t.multiselect.selectAll}
						</button>
					) : (
						<button
							className="link"
							onClick={() => {
								multiselectOffers.enable();
							}}
						>
							<span className="inline md:hidden">{$t.multiselect.enableCompact}</span>
							<span className="hidden md:inline">{$t.multiselect.enable}</span>
						</button>
					))}
				{showMultiSelect && multiselectOffers.value.enabled && (
					<>
						<div className="whitespace-pre"> / </div>
						<button
							className="link"
							onClick={() => {
								multiselectOffers.disable();
								multiselectOffers.unselectAll();
							}}
						>
							<span>{$t.multiselect.disable}</span>
						</button>
					</>
				)}
			</div>
		</>
	);
}
