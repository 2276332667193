import { Pill } from 'components/atoms/Pill';
import { Icon } from 'components/atoms/Icon';
import { onOpen } from 'components/modal/editProfile/signals';
import { localize } from 'i18n/localize';
import { cn } from 'utils/cn';

const $t = localize();

type Props = {
	className: string;
	isCompact?: boolean;
};

export function EditProfileButton({ className, isCompact }: Props) {
	return (
		<Pill
			pillType="secondary"
			className={cn(
				'text-sm md:text-base flex justify-center items-center leading-0 self-center gap-2',
				isCompact ? 'p-2' : 'py-[11px]',
				className,
			)}
			title={isCompact ? $t.bazar.editProfile : ''}
			onClick={onOpen}
		>
			<Icon type="stylus" fill size={22} />
			{!isCompact && $t.bazar.editProfile}
		</Pill>
	);
}
