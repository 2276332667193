import { useCallback } from 'preact/hooks';
import { Icon } from 'components/atoms/Icon';
import { cn } from 'utils/cn';
import { number } from 'utils/format';
import { filters } from 'signals/filters';
import { localize, pluralize } from 'i18n/localize';
import { queryOffers } from 'signals/queryOffers';

const $t = localize();

type Props = {
	className?: string;
	count: number;
};

export function RejectedOfferInfo({ className, count }: Props) {
	const onFilterRejected = useCallback(() => {
		filters.updateStatus('inactive,disabled');
		queryOffers();
	}, []);

	return (
		<div
			className={cn(
				'flex flex-col md:flex-row bg-red-10 rounded-xl p-4 text-sm leading-5 text-center md:text-left items-center md:items-start',
				className,
			)}
		>
			<Icon type="gpp_bad" size={24} fill className="text-red flex-shrink-0 mb-2 md:mr-3" />
			<div>
				<strong className="block mb-2 md:mb-0.5">
					{pluralize(count, $t.rejectedOfferInfoBar.title, { count: number(count) })}
				</strong>
				<p className="mb-3 md:mb-0">
					{$t.rejectedOfferInfoBar.textPart1}{' '}
					<a href={$t.rejectedOfferInfoBar.textPart1LinkHref} className="link" target="_blank">
						{$t.rejectedOfferInfoBar.textPart1Link}
					</a>{' '}
					{$t.rejectedOfferInfoBar.textPart2}
				</p>
				<p>
					{$t.rejectedOfferInfoBar.textPart3}{' '}
					<button onClick={onFilterRejected} className="link">
						{$t.rejectedOfferInfoBar.textLink}
					</button>
				</p>
			</div>
		</div>
	);
}
