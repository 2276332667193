import { filters, type FilterSignalData } from 'signals/filters';
import { userBazar } from 'components/bazar/signals.ts';
import { BazarSellerProfile } from 'components/seller/BazarSellerProfile.tsx';
import { EditProfileButton } from './EditProfileButton';
import { Pill } from 'components/atoms/Pill';
import { VerificationButton } from 'components/modal/verification/VerificationButton';
import FilterPanel from 'components/filters/FilterPanel';
import { OffersCount } from './OffersCount';
import { BazarListIsland } from './BazarListIsland';
import Pagination from 'components/filters/Pagination.tsx';
import BottomAd from 'components/bottomAd/BottomAd';
import { SelectOffers } from 'components/bazar/SelectOffers.tsx';
import { RowsOrCards } from 'components/bazar/RowsOrCards.tsx';
import { FilterSorting } from 'components/filters/FilterSorting.tsx';
import { MultiselectBar } from 'components/bazar/MultiselectBar.tsx';
import { localize } from 'i18n/localize.ts';
import type { ShopData } from 'service/shop/model';
import { categoriesData, categoriesMap, categoriesTree } from 'signals/category';
import { activateCategory, type CategoryTreeCategory } from 'components/modal/categories/signals.ts';
import { type UserEvent } from 'signals/login';
import { useEffect } from 'preact/hooks';
import { dispatchIslandLoaded } from 'utils/islandUtils.ts';
import { SautoAddress } from 'components/atoms/sautoAddress/SautoAddress';
import { SautoPill } from 'components/atoms/sautoPill/SautoPill';
import { EditProfileModal } from 'components/modal/editProfile/EditProfileModal';
import type { GetMixedOffersSearchResponse } from 'service/offer/resource';
import type { CategoryData } from 'service/category/model.ts';
import type { PremiseData } from 'service/premise/model.ts';
import type { UserData } from 'service/user/model.ts';
import { useSignal } from '@preact/signals';
import {
	availableCategories,
	categoryCountsOverride,
	expandedCategories,
} from 'components/filters/categoryList/signals.ts';
import { sendCreateOfferAnalytics } from 'lib/dot/seller/utils.ts';
import { RejectedOfferInfo } from 'components/bazar/RejectedOfferInfo';
import { offers, DEFAULT_OFFERS_VALUE } from 'signals/offers';
import { spaHookupListener, spaUnhookListener } from 'signals/spa';

const $t = localize();

export type Props = {
	isAdmin: boolean;
	shop?: ShopData;
	voucherCount?: number;
	ssrAllCategories: CategoryData[];
	ssrAvailableAllCategories?: CategoryTreeCategory[];
	ssrAvailableCategories: CategoryTreeCategory[];
	ssrAvailableInactiveCategories?: CategoryTreeCategory[];
	ssrCategoryCountsOverride: Record<number, number>;
	ssrExpandedCategories: Record<number, boolean>;
	ssrFilters: FilterSignalData;
	ssrOffers?: GetMixedOffersSearchResponse;
	ssrRejectedOffersCount?: number;
	ssrUser?: UserData | PremiseData;
};

export function BazarIsland({
	isAdmin,
	shop,
	voucherCount,
	ssrAllCategories,
	ssrAvailableCategories,
	ssrCategoryCountsOverride,
	ssrExpandedCategories,
	ssrFilters,
	ssrOffers,
	ssrRejectedOffersCount,
	ssrUser,
}: Props) {
	const isInitialized = useSignal(false);
	if (!isInitialized.peek()) {
		isInitialized.value = true;
		categoriesData.value = ssrAllCategories ?? [];
		filters.value = ssrFilters;
		offers.value = ssrOffers ?? DEFAULT_OFFERS_VALUE;
		userBazar.value = ssrUser ?? null;

		// Signals for bazar filter panel
		availableCategories.value = ssrAvailableCategories;
		categoryCountsOverride.value = ssrCategoryCountsOverride;
		expandedCategories.value = ssrExpandedCategories;

		// Signals are lazily calculated. Trigger them via peek(). We require their side effect.
		categoriesMap.peek();
		categoriesTree.peek();

		const category = filters.peek().category;

		activateCategory(category ? (categoriesMap.peek()[category.id] ?? null) : null);
	}

	useEffect(() => {
		dispatchIslandLoaded('BazarIsland');
		const handleLogin = (event: UserEvent) => {
			if (event.detail.state === 'login') {
				globalThis.window.location.reload();
			}
		};

		const handleLogout = () => {
			globalThis.window.location.reload();
		};

		globalThis.window.addEventListener('login', handleLogin);
		globalThis.window.addEventListener('logout', handleLogout);

		spaHookupListener();

		return () => {
			globalThis.window.removeEventListener('login', handleLogin);
			globalThis.window.removeEventListener('logout', handleLogout);
			spaUnhookListener();
		};
	}, []);

	return (
		<main class="mx-4 md:mx-0">
			<h1 class="text-sm text-dark-blue-50 pb-4">{shop ? $t.bazar.shopOffers : $t.bazar.sellerOffers}</h1>
			<div class="flex flex-row flex-wrap mb-4 gap-4">
				<BazarSellerProfile ssrSeller={ssrUser} shop={shop} />
				<div class="grow"></div>
				{isAdmin && (
					<>
						<EditProfileButton className="bazarButtons:hidden" isCompact />
						<div class="flex flex-row flex-end gap-4 mt-1 items-center w-full md:w-auto">
							<Pill
								pillType="primary"
								as="a"
								href="/nova-nabidka"
								className="py-[9px] md:py-[11px] flex-grow font-bold text-sm md:text-base text-center"
								onClick={() => {
									sendCreateOfferAnalytics('other');
								}}
							>
								{$t.bazar.addOffer}
							</Pill>
							{!ssrUser?.isPremise && ssrUser?.verification !== 'verified' && (
								<VerificationButton className="py-[11px] flex-grow font-bold text-sm md:text-base flex gap-2 justify-center items-center" />
							)}
							<EditProfileButton className="bazarButtons:flex hidden" />
						</div>
					</>
				)}
				{shop && (
					<Pill
						pillType="primary"
						as="a"
						href={shop.link}
						className="py-[10px] md:py-[11px] md:px-[62px] w-full md:w-auto font-bold text-sm md:text-base text-center self-center"
					>
						{$t.bazar.shopLink}
					</Pill>
				)}
				{ssrUser?.isPremise && <SautoPill seller={ssrUser} className="self-center" />}
			</div>
			<div class="flex flex-col md:flex-row justify-center">
				<div class="md:basis-[320px] md:min-w-[286px] md:flex-shrink-[1] md:mr-6 lg:mr-8">
					{ssrUser?.isPremise && (
						<div class="mb-4">
							<SautoAddress seller={ssrUser} />
						</div>
					)}
					{ssrUser && isAdmin && <hr class="md:hidden mb-4 -mx-4 text-gray" />}
					<FilterPanel isAdmin={isAdmin} seller={isAdmin ? ssrUser : filters.peek().seller} shop={shop} />
				</div>
				<div class="flex-grow-[10000] flex-shrink-[1] md:basis-[700px] 4xl:max-w-screen-offer-card-list relative">
					<div class="flex justify-between items-center my-6 md:my-5 md:mt-0">
						<div class="flex justify-center shrink-0">
							<OffersCount className="flex-shrink-0" />
							{isAdmin && <SelectOffers isAdmin={isAdmin} />}
						</div>
						<div class="flex flex-row justify-between gap-2 md:gap-4">
							{isAdmin && <RowsOrCards />}
							<FilterSorting noTooltip />
						</div>
					</div>
					{isAdmin && !!ssrRejectedOffersCount && (
						<RejectedOfferInfo count={ssrRejectedOffersCount} className="mb-4" />
					)}
					<BazarListIsland isAdmin={isAdmin} ssrOffers={offers.peek()} voucherCount={voucherCount} />
					<div class="flex justify-center mt-8">
						<Pagination paginationDataSource={shop ? 'shop' : 'bazar'} />
					</div>
					<MultiselectBar className="mt-8 md:-mx-4" />
					<div data-vtbot-replace="bottomAd">
						<BottomAd />
					</div>
				</div>
			</div>
			<EditProfileModal />
		</main>
	);
}
