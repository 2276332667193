import { BaseSellerProfile, type BaseSellerProfileProps } from './BaseSellerProfile.tsx';

type Props = Pick<BaseSellerProfileProps, 'className' | 'ssrSeller' | 'shop' | 'searchType'>;

/**
 *
 * @param className
 * @param shop
 * @param avatarClassName
 * @param searchType If present, the link will set up search analytics by setting searchMeta in SessionStorage
 * @constructor
 */
export function BazarSellerProfile({ className, ssrSeller, shop, searchType }: Props) {
	return (
		<BaseSellerProfile
			className={className}
			ssrSeller={ssrSeller}
			shop={shop}
			searchType={searchType}
			type="bazar"
		/>
	);
}
