import { useSignal, Signal } from '@preact/signals';
import { Modal } from 'components/modal/Modal.tsx';
import { ModalHeader } from 'components/modal/ModalHeader.tsx';
import { Icon } from 'components/atoms/Icon.tsx';
import { Image } from 'components/atoms/Image.tsx';
import { Pill } from 'components/atoms/Pill.tsx';
import { localize } from 'i18n/localize.ts';
import { tld } from 'utils/env.ts';
import { navigate } from 'astro:transitions/client';

const $t = localize();

type Props = {
	onClose: () => void;
};

const BANK_ID_URL = `https://login.sbazar.${tld()}/api/v1/bankid/auth?continue=`;
const MOJE_ID_URL = `https://login.sbazar.${tld()}/api/v1/mojeid/auth?continue=`;

const VARIANT = {
	BANK_ID: 'bankId',
	MOJE_ID: 'mojeId',
	UNSELECTED: null,
} as const;

type Variant = (typeof VARIANT)[keyof typeof VARIANT];

function selectedVariantToTitle(selectedVariant: Variant) {
	switch (selectedVariant) {
		case VARIANT.BANK_ID:
			return $t.verification.modalTitleBankId;
		case VARIANT.MOJE_ID:
			return $t.verification.modalTitleMojeId;
		default:
			return $t.verification.modalTitle;
	}
}

export function VerificationModal({ onClose }: Props) {
	const selectedVariant = useSignal<Variant>(null);
	const title = selectedVariantToTitle(selectedVariant.value);

	const bankIdVerify = () => {
		const returnUrl = encodeURI(globalThis.window.location.href);
		navigate(`${BANK_ID_URL}${returnUrl}`);
	};

	const mojeIdVerify = () => {
		const returnUrl = encodeURI(globalThis.window.location.href);
		navigate(`${MOJE_ID_URL}${returnUrl}`);
	};

	return (
		<Modal
			className="bg-neutral-white w-[800px] max-w-full px-6 md:px-8 py-6 overflow-auto max-h-max"
			onClickAway={onClose}
			onClose={onClose}
		>
			<ModalHeader
				className="mb-4 md:mb-8"
				titleClassName="text-base md:text-3xl"
				header={title}
				onClose={onClose}
			/>
			{selectedVariant.value === VARIANT.BANK_ID ? (
				<BankIdScreen onClose={onClose} onVerify={bankIdVerify} />
			) : selectedVariant.value === VARIANT.MOJE_ID ? (
				<MojeIdScreen onClose={onClose} onVerify={mojeIdVerify} />
			) : (
				<ChoiceScreen selectedVariant={selectedVariant} />
			)}
		</Modal>
	);
}

type VariantProps = {
	onClose: () => void;
	onVerify: () => void;
};

function BankIdScreen({ onClose, onVerify }: VariantProps) {
	return (
		<div class="font-medium text-xs md:text-base">
			<div class="flex">
				<div>
					<h2 class="text-base md:text-2xl font-bold">{$t.verification.advantages}</h2>
					<ul class="mt-3 md:mt-4 pl-6 list-image-tick">
						<li class="mb-3 md:mb-1.5">{$t.verification.bankId}</li>
						<li class="mb-3 md:mb-1.5">{$t.verification.badge}</li>
						<li class="mb-3 md:mb-1.5">{$t.verification.fraudBankId}</li>
					</ul>
				</div>
				<Image
					src="/icon/verification/illustration.svg"
					alt=""
					class="float-right w-[63px] md:w-[121px] h-min flex-shrink-0 ml-6 mt-7 md:mt-0"
				/>
			</div>
			<h2 class="text-base md:text-2xl font-bold mb-3 md:mb-4">{$t.verification.verificationWorks}</h2>
			<ul class="inline-block list-disc marker:text-gray-marker pl-6 mb-6">
				<li class="mb-3 md:mb-1.5">{$t.verification.verificationThroughBankId}</li>
				<li class="mb-3 md:mb-1.5">{$t.verification.synchronizeBankId}</li>
				<li class="mb-3 md:mb-1.5">{$t.verification.verifiedSeller}</li>
				<li class="mb-3 md:mb-1.5">
					{$t.verification.allInfo}{' '}
					<a
						href="https://napoveda.seznam.cz/cz/bankid-overeni/"
						target="_blank"
						rel="noreferrer noopener"
						class="text-red hover:text-red-focus focus:text-red-focus underline underline-offset-2 hover:no-underline focus:no-underline"
					>
						{$t.verification.help} &rsaquo;
					</a>
				</li>
			</ul>
			<div class="flex -mx-6 px-6 md:-mx-8 md:px-8 py-4 md:py-6 bg-light-gray text-dark-blue-50 mb-6 md:mb-8">
				<Icon className="mr-3 text-dark-blue-25 flex-shrink-0" type="info" size={24} fill />
				{$t.verification.approvedBankId}
			</div>
			<div class="flex flex-col md:flex-row gap-4 md:gap-6">
				<Pill
					pillType="secondary"
					className="flex-1 py-[9px] md:py-[11px] font-bold text-sm md:text-base"
					onClick={onClose}
				>
					{$t.verification.verifyLater}
				</Pill>
				<Pill
					pillType="primary"
					className="flex-1 py-[9px] md:py-[11px] font-bold text-sm md:text-base"
					onClick={onVerify}
				>
					{$t.verification.verifyNow}
				</Pill>
			</div>
		</div>
	);
}

function MojeIdScreen({ onClose, onVerify }: VariantProps) {
	return (
		<div class="font-medium text-xs md:text-base">
			<div class="flex">
				<div>
					<h2 class="text-base md:text-2xl font-bold">{$t.verification.advantages}</h2>
					<ul class="mt-3 md:mt-4 pl-6 list-image-tick">
						<li class="mb-3 md:mb-1.5">{$t.verification.mojeId}</li>
						<li class="mb-3 md:mb-1.5">{$t.verification.badge}</li>
						<li class="mb-3 md:mb-1.5">{$t.verification.fraudMojeId}</li>
					</ul>
				</div>
				<Image
					src="/icon/verification/illustration.svg"
					alt=""
					class="float-right w-[63px] md:w-[121px] h-min flex-shrink-0 ml-6 mt-7 md:mt-0"
				/>
			</div>
			<h2 class="text-base md:text-2xl font-bold mb-3 md:mb-4">{$t.verification.verificationWorks}</h2>
			<ul class="inline-block list-disc marker:text-gray-marker pl-6 mb-6">
				<li class="mb-3 md:mb-1.5">{$t.verification.verificationThroughMojeId}</li>
				<li class="mb-3 md:mb-1.5">{$t.verification.accessMojeId}</li>
				<li class="mb-3 md:mb-1.5">{$t.verification.synchronizeMojeId}</li>
				<li class="mb-3 md:mb-1.5">{$t.verification.verifiedSeller}</li>
				<li class="mb-3 md:mb-1.5">
					{$t.verification.allInfo}{' '}
					<a
						href="https://napoveda.seznam.cz/cz/login/overeni-seznam-uctu/overeni-pomoci-mojeid/"
						target="_blank"
						rel="noreferrer noopener"
						class="text-red hover:text-red-focus focus:text-red-focus underline underline-offset-2 hover:no-underline focus:no-underline"
					>
						{$t.verification.help} &rsaquo;
					</a>
				</li>
			</ul>
			<div class="flex -mx-6 px-6 md:-mx-8 md:px-8 py-4 md:py-6 bg-light-gray text-dark-blue-50 mb-6 md:mb-8">
				<Icon className="mr-3 text-dark-blue-25 flex-shrink-0" type="info" size={24} fill />
				{$t.verification.approvedMojeId}
			</div>
			<div class="flex flex-col md:flex-row gap-4 md:gap-6">
				<Pill
					pillType="secondary"
					className="flex-1 py-[9px] md:py-[11px] font-bold text-sm md:text-base"
					onClick={onClose}
				>
					{$t.verification.verifyLater}
				</Pill>
				<Pill
					pillType="primary"
					className="flex-1 py-[9px] md:py-[11px] font-bold text-sm md:text-base"
					onClick={onVerify}
				>
					{$t.verification.verifyNow}
				</Pill>
			</div>
		</div>
	);
}

function ChoiceScreen({ selectedVariant }: { selectedVariant: Signal<Variant> }) {
	return (
		<div class="text-dark-blue-50">
			<div class="text-sm md:text-base mb-3 md:mb-4 font-bold">{$t.verification.choiceScreenTitle}</div>
			<div class="flex gap-6 flex-col md:flex-row">
				<ChoiceVariant
					buttonLabel={$t.verification.choiceScreenBankIdButton}
					imagePath={'/icon/verification/bankId.svg'}
					imageTitle={$t.verification.choiceScreenBankIdImage}
					description={$t.verification.choiceScreenBankId}
					onClick={() => (selectedVariant.value = VARIANT.BANK_ID)}
				/>
				<ChoiceVariant
					buttonLabel={$t.verification.choiceScreenMojeIdButton}
					imagePath={'/icon/verification/mojeId.png'}
					imageTitle={$t.verification.choiceScreenMojeIdImage}
					description={$t.verification.choiceScreenMojeId}
					onClick={() => (selectedVariant.value = VARIANT.MOJE_ID)}
				/>
			</div>
		</div>
	);
}

function ChoiceVariant({
	buttonLabel,
	imagePath,
	imageTitle,
	description,
	onClick,
}: {
	imagePath: string;
	imageTitle: string;
	description: string;
	buttonLabel: string;
	onClick: () => void;
}) {
	return (
		<div class="flex flex-1 flex-col items-center bg-gray-light-background p-4 md:p-6 pt-9 md:pt-[72px] text-center rounded-2xl">
			<Image
				src={imagePath}
				alt={imageTitle}
				class="max-w-full h-[32px] md:h-[39px] flex-grow-0 flex-shrink-0 mb-8 md:mb-14 mix-blend-darken"
			/>
			<div class="font-base font-medium flex-1 mb-6 md:mb-[33px]">{description}</div>
			<Pill
				pillType="primary"
				className="flex-grow-0 flex-shrink-0 w-full text-sm md:text-base font-bold px-2 py-[9px] md:py-[11px]"
				onClick={onClick}
			>
				{buttonLabel}
			</Pill>
		</div>
	);
}
